import React from 'react';
import { Link } from 'gatsby';
import mdToHtml from '../util/mdToHtml';
import mast from '../img/Aeroseal_Masthead_Residential.jpg';
import angledHomes from '../img/Aeroseal_Houses_Residential.png';
import formBg from '../img/AerosealBarrie_Homepage_Contact_BG.jpg';
import duct1 from '../img/Aeroseal_Duct_1.svg';
import duct2 from '../img/Aeroseal_Duct_2.svg';
import duct3 from '../img/Aeroseal_Duct_3.svg';
import quote from '../img/Aeroseal_icon_quote.svg';
import QuoteForm from '../components/QuoteForm';
import BotttomLogoSection from '../components/BottomLogoSection';

import '../styles/ResidentialTemplate.scss';

const ResidentialTemplate = (props) => {
	return (
		<main className="ResidentialTemplate">
			<section className="hero is-light is-bold">
				<div
					className="hero-body"
					style={{
						backgroundImage: `url(${mast})`,
					}}
				>
					<div className="container">
						<div className="columns">
							<div className="column is-7 is-hidden-mobile" />
							<div className="column is-5">
								<h1>
									{props.content.masthead_heading}
								</h1>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section residentialBlurb">
				<div className="container">
					<div className="columns">
						<div className="column is-8 firstCol">
							<h2>
								{props.content.first_section_heading}
							</h2>
							<p>
								{props.content.first_section_content}
							</p>
						</div>
						<div className="column is-4 has-text-centered secondCol">
							<h2>{props.content.second_section_heading}</h2>
							<h3>{props.content.comfort_stat}</h3>
							<p>{props.content.comfort_content}</p>
							<h3>{props.content.saved_stat}</h3>
							<p>{props.content.saved_content}</p>
						</div>
					</div>
				</div>
			</section>

			<img src={angledHomes} alt="Row of homes" className="rowHouses" />

			<section className="is-inverted is-skewed ducts">
				<div className="section is-medium">
					<h2 className="has-text-centered">{props.content.process_heading}</h2>
					<div className="columns is-gapless">
						<div className="column is-half">
							<img src={duct1} alt="" className="ductImage" />
						</div>
						<div className="column is-half is-flex-tablet">
							<div style={{ margin: 'auto 0', maxWidth: '32rem' }}>
								<h3>Step 1:</h3>
								<p>{props.content.step_1}</p>
							</div>
						</div>
					</div>
					<div className="columns is-gapless">
						<div className="column is-half">
							<img src={duct2} alt="" className="ductImage" />
						</div>
						<div className="column is-half is-flex-tablet">
							<div style={{ margin: 'auto 0', maxWidth: '32rem' }}>
								<h3>Step 2:</h3>
								<p>{props.content.step_2}</p>
							</div>
						</div>
					</div>
					<div className="columns is-gapless">
						<div className="column is-half">
							<img src={duct3} alt="" className="ductImage" />
						</div>
						<div className="column is-half is-flex-tablet">
							<div style={{ margin: 'auto 0', maxWidth: '32rem' }}>
								<h3>Step 3:</h3>
								<p>{props.content.step_3}</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div
				className="section has-text-centered testimony"
				style={{ paddingTop: '5rem', paddingBottom: '2rem' }}
			>
				<div className="container">
					<h2>{props.content.testimonials_heading}</h2>
					<div className="columns" style={{ margin: '2rem 0' }}>
						<div className="column has-text-left">
							<div className="has-text-centered">
								<img src={quote} alt="quotation" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: mdToHtml(props.content.testimony_1_content) }}></div>
						</div>
						<div className="column has-text-left">
							<div className="has-text-centered">
								<img src={quote} alt="quotation" />
							</div>
							<div dangerouslySetInnerHTML={{ __html: mdToHtml(props.content.testimony_2_content) }}></div>
						</div>
					</div>
					<Link to="/faq">
						<button className="is-button">LEARN MORE</button>
					</Link>
				</div>
			</div>

			<section
				className="is-inverted is-skewed"
				style={{
					backgroundImage: `url(${formBg})`,
					backgroundPosition: 'center center',
					backgroundSize: 'cover',
				}}
			>
				<div
					className="section"
					style={{
						paddingTop: '16rem',
						paddingBottom: '12rem',
						marginBottom: '-6rem',
					}}
				>
					<div className="container">
						<div className="columns">
							<div className="column">
								<div className="contactInner">
									<h2>{props.content.contact_heading}</h2>
									<p>{props.content.contact_content}</p>
									<h3>
										<a className="phonelink" href="tel:17052149610">
											(705)&nbsp;214-9610
										</a>
									</h3>
								</div>
							</div>
							<div className="column has-text-centered">
								<div className="contactInner">
									<QuoteForm />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<BotttomLogoSection />
		</main>
	);
};

export default ResidentialTemplate;
