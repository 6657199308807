import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ResidentialTemplate from '../templates/ResidentialTemplate';
import Helmet from 'react-helmet';

const HomePage = ({ data }) => {
	const content = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<Layout>
			<Helmet>
				<title>Aeroseal Barrie | Residential</title>
			</Helmet>
			<ResidentialTemplate content={content} />
		</Layout>
	);
};

export default HomePage;

export const query = graphql`
	{
		allMarkdownRemark(filter: {frontmatter: {slug: {eq: "residential"}}}) {
			edges {
				node {
					frontmatter {
						masthead_heading
						first_section_heading
						first_section_content
						second_section_heading
						comfort_stat
						comfort_content
						saved_stat
						saved_content
						process_heading
						step_1
						step_2
						step_3
						testimonials_heading
						testimony_1_content
						testimony_2_content
						contact_heading
						contact_content
					}
				}
			}
		}
	}
`;